li{
    list-style-type: none;
}
.spotinfo{
    width: auto;
}
.spotscontainer{
    width: auto;
    display: inline-block;
    text-align: left;


}

.topBar{
    display:flex;
    justify-content: space-between;

}
img{
    width: 400px;
    height:290px;
    border-radius: 15px;
}
.spotname{
    display: flex;
    justify-content: center;
}
.spotClickable{
    width:400px;
}
.spotClickable:hover{
    cursor: pointer;
}
.stars{
    display:flex;
    justify-content: right;
}

.tooltip-wrap {
    display: inline-block;
    margin: 15px 80px;
    max-width: 300px;
    position: relative;

    width:fit-content
  }

  .tooltip-content {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 1s;

    width: max-content;
  }

  .tooltip-wrap:hover .tooltip-content {
    opacity: 1;
  }
