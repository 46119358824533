.form{
    display:flex;
    flex-direction: column;
    align-items: left;
    max-width: 600px;
    margin-left: 90px;
}
.formContainer{
    display: flex;
    justify-content: center;
}
.input{
    display: flex;
    width: 550px;
    margin: 15px 0px

}
.label{
    display:flex;
    width:550px;
    flex-direction: column;
    align-items: left;
}
#createSpot{
    width:fit-content;

}
.btnContainer{
    display: flex;
    justify-content: center;
    margin: 15px 0px;
}
.bottomBorders{
    padding-bottom: 20px;
    border-bottom: 3px solid black;
}
