.spotDetailContainer{
    margin: 20px 15px 20px 10px;
    max-width: 1000px;
}

.imageOne{
    grid-area: one;
    width: 100%;
    height: 420px;
    display: flex;
    align-items: first baseline;
    justify-content: center;
}
#imgOne{
    height: 410px;
    width: 460px;
}

.imageTwo{
    grid-area: two;
    height: 200px;
    width:230px;
}
.imageThree{
    grid-area: three;
    height: 200px;
    width:230px;

}
.imageFour{
    grid-area: four;
    height: 200px;
    width:230px;

}
.imageFive{
    grid-area: five;
    height: 200px;
    width:230px;

}


.imgTwo{

    height: fit-content;
    display: flex;
    justify-content: left;
    align-items: last baseline;
}
.imgThree{

    height: fit-content;
    display: flex;
    justify-content: left;
    align-items: last baseline;
}
.imgFour{

    height: fit-content;
    display: flex;
    justify-content: left;
    align-items: first baseline;
}

.imgFive{

    height: fit-content;
    display: flex;
    justify-content:left;
    align-items:first baseline;
}
.allImages{
    display: grid;
    gap: 0px 9px;
  max-width: 1000px;
  height: 400px;
  grid-template-areas:
  "one one two three"
  "one one four five";
}

.calloutBox{
    margin-top: 10px;
    width: 250px;
    padding: 10px;
    border: 3px solid black;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
}

.topRow{
    display: flex;
    justify-content: space-between;
}

.rightSide{
    display: flex;
}

button{
    border: 2px solid black;
    background-color: #e6585d;
    color: white;
    font-weight:550;
    margin:10px 0px 10px 0px;
    padding: 5px 10px;
    box-shadow: 2px 5px 3px 2px rgb(34, 22, 22);
}

.details{
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 30px 0px;
    display:flex;
    justify-content: space-between;
    align-items:first baseline;
    border-bottom: 3px solid gainsboro;
}

.description{
    inline-size: 620px;
    overflow-wrap: break-word;
}
.reviewDetails{
    display: flex;

}
.dot{
    margin: 0px 3px 0px 3px
}

.reviewTitle{
    font-size: 18.72px;
    margin-bottom: 18px;
}

.reviewContent{
    margin-top: 10px;
    margin-bottom: 50px;
}
.newStar{
    position: relative;
    top:1px;
}
.lowerStar{
    margin-bottom: 15px;
}
