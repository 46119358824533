#modal-content{
    border-radius: 9px;
    padding: 20px 10px 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:520px;
}
button:disabled{
    background-color: #cccccc;
    color:grey
}
button:hover{
    cursor: pointer;
}
.errors{
    color: red;
}
.labelInput{
    display:flex;
    flex-direction: column;
    margin: 15px 0px;
}
.loginInput{
    margin:10px 0px;
}
#loginbtn{
    display: flex;
    justify-content: center;
}
